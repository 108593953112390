<template>
  <div>
    <div :style="getElementStyle">
      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24">
          <label for="text">{{ data.question_text }}</label>
          <span
            v-if="data.validations.required"
            style="color:red;font-weight:bold;"
          >
            *
          </span>
          <i
        class="el-icon-copy-document "  v-if="!isDataTableField"  @click="copyDocument" >
      </i>
          <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
        </el-col>
        <el-col
          :span="showLabel && !isDefalutPos ? 12 : 24"
          v-if="data.question_type == 'MCQ'"
        >
          <div>
            <el-checkbox-group
              v-model="localValue"
              :max="data.max_selection || undefined"
              v-if="
              data.question_options_format &&
              data.question_options_format != 'DROP_DOWN'
            "
            >
              <ol :class="data.question_options_format.toLowerCase()">
                <li
                  v-for="(option, index) of data.options"
                  :key="index"
                >
                  <el-checkbox
                    :value="option"
                    :label="option"
                    border
                  >
                    {{ String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)) }}.
                    {{ option }}</el-checkbox>
                </li>
              </ol>
            </el-checkbox-group>

            <el-select
              v-model="localValue"
              multiple
              :multiple-limit="data.max_selection || undefined"
              v-else
              collapse-tags
              style="margin-top: 7px;"
            >
              <el-option
                v-for="(option, index) of data.options"
                :key="index"
                :value="option"
                :label="option"
              ></el-option>
            </el-select>
          </div>
        </el-col>

        <el-col
          :span="showLabel && !isDefalutPos ? 12 : 24"
          v-if="data.question_type == 'TEXT'"
        >
          <div>
            <el-input
              type="textarea"
              :autosize="false"
              :placeholder="data.placeholder"
              v-model="selectedValue"
              style="margin-top: 7px;"
              :suffix-icon="data.properties.el_icon"
            ></el-input>
          </div>
        </el-col>
        <el-col
          :span="showLabel && !isDefalutPos ? 12 : 24"
          v-if="data.question_type == 'YES_OR_NO'"
        >
          <el-radio-group
            :style="getStyle"
            :model="selectedValue"
          >
            <el-radio
              label="YES"
              border
            >YES</el-radio>
            <el-radio
              label="NO"
              border
            >NO</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <span
        v-if="isActive || isDataTableField"
        class="setting-icon"
        @click="openSettings"
      >
        <i class="el-icon-s-tools" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["data", "value", "isActive", "isDataTableField"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.style &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.style &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },

    getLabel() {
      return index => {
        return `${String.fromCharCode((index % 52) + (index > 25 ? 71 : 65))} . ${
          this.data.options[index]
        }`;
      };
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      isDefalutPos: true,
      showLabel: true,
      localValue: [],
      max: 2
    };
  },
  mounted() {
    this.options = this.data.options || [];
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }

    this.max = this.data.max_selection || this.options.length;
  },
  watch: {
    "data.options": {
      handler() {
        this.options = this.data.options || [];
      }
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>


<style lang="scss">
.horizantal_list {
  display: inline-flex;
  gap: 10px;
  padding-left: 0;
  margin-top: 10px;
}
.vertical_list {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-left: 0;
  margin-top: 10px;
}
.el-select .el-select__tags>span {
    display: flex;
}
</style>